<script>
/* eslint-disable */
import Chart from 'chart.js'
import { Line, mixins, generateChart } from 'vue-chartjs'
import helper from '@/utils/helper'
import _ from 'lodash'
import constants from '@/constants/constants'

Chart.defaults.LineWithLine = Chart.defaults.line
Chart.defaults.global.hover.intersect = false
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    Chart.controllers.line.prototype.draw.call(this, ease)

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      const activePoint = this.chart.tooltip._active[0]
      const ctx = this.chart.ctx
      const x = activePoint.tooltipPosition().x
      const topY = this.chart.legend.bottom
      const bottomY = this.chart.chartArea.bottom
      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, topY)
      ctx.lineTo(x, bottomY)
      ctx.lineWidth = 1
      ctx.strokeStyle = constants.gridLinesColors.grey,
      ctx.stroke()
      ctx.restore()
    }
  },
})

const BCMChart = generateChart('bitcoinmetrics-chart', 'LineWithLine')

export default {
  name:'Chart',
  extends: BCMChart,
  mixins: mixins.reactiveProp,
  props: {
    'params': { type: Object,default: () => {} },
  },
  data: () => ({
    helper: helper,
    chart: null,
  }),
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        animation: {
          duration: 0.5,
        },
        scales: {
          yAxes: [
            {
              id: 'yAxis',
              type: 'linear',
              ticks: {
                fontSize: 10,
                fontColor: constants.chartAxisColors.primary,
                callback: (value, index, values) => {
                  const price = parseFloat(value) < 1000 ? helper.convertToBigNumber(parseFloat(value).toFixed(4), 4)
                    : helper.convertToBigNumber(parseFloat(value).toFixed(2),2)

                  return `${
                    this.params.currency ? '$' : ''
                  }${price}`
                },
              },
              gridLines: {
                color: constants.gridLinesColors.grey,
                lineWidth: 1,
                zeroLineColor: constants.gridLinesColors.grey,
              }
            },
          ],
          xAxes: [
            {
              id: 'xAxis',
              ticks: {
                fontSize: 10,
                fontColor: constants.chartAxisColors.primary,
              },
              gridLines: {
                color: constants.gridLinesColors.grey,
                lineWidth: 1,
                zeroLineColor: constants.gridLinesColors.grey,
              },
              afterTickToLabelConversion: function (data) {
                const xLabels = data.ticks
                let tempLabel = ''

                xLabels.forEach((labels, i) => {
                  const xlabel = helper.datetimeWithMonth(xLabels[i])
                  if (tempLabel !==  xlabel) {
                    xLabels[i] = xlabel
                  } else {
                    xLabels[i] = ''
                  }
                  tempLabel = helper.datetimeWithMonth(labels)
                })
              },
            },
          ],
        },
        tooltips: {
          mode: 'x-axis',
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              return `${helper.convertToBigNumber(
                tooltipItems.yLabel,
                2,
              )}`
            },
          },
        },
        height: '370px',
      }
    },
  },
  watch: {
    '$store.state.overview.chartData': {
      handler: function (value) {
        this.createChart()
      },
      deep: true,
    },
    '$vuetify.theme.dark': {
      handler: function (value) {
        this.$vuetify.theme.dark = value
        this.createChart()
      },
      deep: true,
    },
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChart() {
      const chartParam = this.$store.getters['overview/getChartData']

      this.addCustomPlugin()
      this.renderChart(
        {
          labels: chartParam.label,
          datasets: [
            {
              data:  chartParam.data,
              label: `${ chartParam.name ? chartParam.name : ''} ${this.params.currency ? `/${this.params.currency.toUpperCase()}` : ''}`,
              backgroundColor: 'transparent',
              borderColor: constants.chartAxisColors.primary,
              borderWidth: 1,
              fill: false,
              pointRadius: 0,
              pointHitRadius: 2,
              pointBackgroundColor: this.$vuetify.theme.dark ? constants.chartAxisColors.white : constants.chartAxisColors.black,
              pointHoverBackgroundColor: 'transparent',
              pointHoverRadius: 2,
            },
          ],
        },
        this.options,
      )
    },
    addCustomPlugin() {
      this.addPlugin({
        afterDraw: (chart) => {
          const ctx = chart.ctx

          chart.ctx.canvas.style.backgroundImage = 'none'

          const image = new Image()
          image.src = require('@/assets/images/bcm-chart-small.png')
          image.height = 70
          image.width = 300

          ctx.drawImage(
            image,
            (chart.chart.width / 2) - 300 / 2, // x asix
            (chart.chart.height / 2) - 70 / 2 //y axis
          )

          ctx.save()
          ctx.restore()
        }
      })
    },
  },
}
</script>

<style lang="scss">
.title {
    font-size: 47px;
    font-weight: bold;
    opacity: 0.2;
    z-index: -1;
    margin-top: 20px;
    position: absolute;
}

canvas {
  background-image: url('~@/assets/images/bcm-chart-small.png');
  background-position: center center;
  background-size: 300px 70px;
  background-color: $card_bg;
}
</style>
