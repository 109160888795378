<template>
  <v-container fluid>
    <Loader v-if="loading" class="pt-16 pb-10"/>
    <v-row dense class="mb-4 mx-1">
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <h1>{{ `${ $store.state.overview.name } ${ $t('Chain') } ${ $t('Activity') }` }}</h1>
          </v-col>
        </v-row>
        <v-divider class="my-3"/>
        <v-card class="card_bg">
          <v-row dense>
            <v-col cols="12">
              <v-btn-toggle
                v-model="days"
                class="d-flex mt-3 px-4 float-right toggle-controls"
                dense
                mandatory
              >
                <v-btn
                  v-for="(day, key) in daysGroup"
                  :key="key"
                  class="body-2 uncapitalize"
                  @click="onChangeDays(day.name)"
                >
                  <span>{{ day.name }}</span>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <Loader v-if="chart_loading" class="pt-16 pb-10"/>
              <LineChart
                v-else
                :params="params"
                :labels="labels"
                :chart-data="chartData"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LineChart from '@/components/organisms/chart/Chart'
import Loader from '@/components/molecules/Loader'
import { CURRENCY_ACTIVITY_DETAILS_REQUEST } from '@/store/overview/action'
import { get, dispatch, commit, call } from 'vuex-pathify'
import common from '@/shared/common'

export default {
  name:'ChainActivityDetails',
  components: {
    LineChart,
    Loader,
  },
  data: () => ({
    chart_loading: false,
    labels: [],
    chartData: [],
    days: '7D',
    common: common,
    daysGroup: common.daysGroup.filter((x) => x.name !== '1D'),
    params: {},
  }),
  computed: {
    loading: get('loading'),
  },
  async mounted() {
    this.$route.params.id =
        typeof this.$route.params.name === 'string'
          ? parseFloat(this.$route.params.name)
          : this.$route.params.id

    commit('SHOW_LOADER', true)
    await this.onFetchCurrencyActivity()
    commit('SHOW_LOADER', false)
  },
  methods: {
    ...call('overview',['CURRENCY_ACTIVITY_DETAILS_REQUEST']),
    async onChangeDays(val) {
      this.days = val
      this.chart_loading = true
      await this.onFetchCurrencyActivity()
      this.chart_loading = false
    },
    async onFetchCurrencyActivity() {
      const payload = {
        sort: 'asc',
        id: this.$route.params.name,
        days: this.days,
      }

      await this.CURRENCY_ACTIVITY_DETAILS_REQUEST(payload)
    },
  },

}
</script>
